/* Importing Tailwind base styles, components, and utilities */

@tailwind base;
@tailwind components;
@tailwind utilities;


/*
 *  Container class with a maximum width of 1240px, auto margins for centering, and padding =====
 */

.container {
  max-width: 1240px;
  @apply mx-auto px-5;
}



/*
 *   Typography Classes =====
 */

.font-poppins-32-regular {
  @apply font-poppins text-xl md:text-[32px] font-normal leading-[38.4px] text-wireframe-heading;
}

.font-rage-38-regular {
  @apply font-rage text-4xl sm:text-[38px] font-normal leading-[45.6px] text-primary-6;
}

.font-poppins-32-medium {
  @apply font-poppins text-[32px] font-medium leading-[38.4px] text-wireframe-heading;
}

.font-inter-20-semibold {
  @apply font-inter text-xl font-semibold leading-[24px] text-wireframe-heading;
}

.font-inter-16-regular {
  @apply font-inter text-[16px] font-normal leading-[22.4px] text-gray-5;
}

.font-inter-14-regular {
  @apply font-inter text-[14px] font-normal leading-[22.4px] text-gray-5;
}


/*
 *  Custom Carousel Styles =====
 */

.carousel-container {
  @apply relative flex justify-center items-center w-full;
  height: 430px;
}

.cards {
  @apply relative flex justify-center items-center;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.card {
  @apply absolute transition-transform duration-500 ease-in-out md:w-[400px] md:h-[270px] min-[991px]:w-[470px] min-[991px]:h-[320px] lg:w-[550px] lg:h-[400px] xl:w-[720px] xl:h-[430px];
}

.image img {
  @apply rounded-lg object-fill w-full h-full transition-all duration-700 ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.activeSlide .image img {
  filter: none;
}

.nextSlide .image img,
.prevSlide .image img,
.nextSlide2 .image img,
.prevSlide2 .image img {
  filter: blur(3px);
}

.activeSlide {
  transform: translateX(0) scale(1);
  z-index: 10;
}

.nextSlide {
  transform: translateX(25%) scale(0.95);
  z-index: 5;
}

.prevSlide {
  transform: translateX(-25%) scale(0.95);
  z-index: 5;
}

.nextSlide2 {
  transform: translateX(40%) scale(0.9);
  z-index: 1;
}

.prevSlide2 {
  transform: translateX(-40%) scale(0.9);
  z-index: 1;
}



/* Swiper Modifing Css */

.tmbMySwiper .swiper-button-next:after {
  content: url('/icons/slider-right.svg');
  margin-left: -5px;
}

.tmbMySwiper .swiper-button-prev:after {
  content: url('/icons/slider-right.svg');
  transform: rotate(180deg);
  margin-left: 5px;
}

/* .tmbMySwiper.swiper {
  @apply px-[25px] md:w-[calc(100%+50px)] md:ml-[-25px];
} */

.slider-sec .swiper-button-prev,
.slider-sec .swiper-button-next {
  @apply hidden;
}


/*
 *  Scroll bar Designed =====
 */

::-webkit-scrollbar-track {
  @apply bg-gray-2 rounded-[10px];
}

::-webkit-scrollbar {
  @apply bg-gray-2 w-2 rounded-[10px];
}

::-webkit-scrollbar-thumb {

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  @apply bg-gray-5 rounded-[10px] w-[6px];
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}



/*
 * Reservation Sidebar Silder =====
 */
.sidebar-slider .swiper-pagination-bullet,
.offers_promotions .swiper-pagination-bullet
{
  @apply w-2 h-2 bg-primary-4 rounded-full;
}
.bannerSwiper .swiper-pagination-bullet{
  @apply w-2.5 h-2.5 !bg-white/50 rounded-full; 
}

.sidebar-slider .swiper {
  @apply static;
}
.offers_promotions .tmbMySwiper{
  @apply pb-10
}
.sidebar-slider .swiper-pagination
{
  @apply !-bottom-6 text-left;
}
.offers_promotions .swiper-pagination{
  @apply !bottom-[5px] text-start;
}

.sidebar-slider .swiper-pagination-bullet.swiper-pagination-bullet-active,
.offers_promotions .swiper-pagination-bullet.swiper-pagination-bullet-active
{
  @apply w-6;
}
.bannerSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active{
  @apply w-8 !bg-primary-5; 
}

body .swiper-pagination {
  z-index: 1;
}

/*
 * Menu Silder =====
 */
.menu-slider .swiper-pagination-bullet {
  @apply md:w-2.5 md:h-2.5 bg-primary-4 rounded-full;
}

.menu-slider .swiper {
  @apply static;
}

.menu-slider .swiper-pagination {
  @apply bottom-1 flex items-center justify-center;
}

.menu-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply w-3 h-3 md:w-4 md:h-4;
}



/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
/* home slider css */
.testimonialSlider .swiper-pagination {
  @apply absolute md:!bottom-[50px] !bottom-0 md:right-[40px] max-w-fit !left-[50%] max-[767px]:translate-x-[-50%] md:!left-auto flex items-center;
}
.testimonialSlider .swiper-pagination-bullet{
  @apply bg-wireframe-primary w-[11px] h-[11px] rounded-full opacity-[1];
}
.testimonialSlider .swiper-pagination-bullet.swiper-pagination-bullet-active{
  @apply bg-white w-4 h-4
}
.hide-scroller::-webkit-scrollbar{
  appearance: none;
  display: none;
}
.testimonialSlider .swiper-wrapper {
  @apply md:p-0 pb-7;
}
/* home slider css */

/* Review Section Slider in Restaurant-detail page */
.testimonialSlider.reviews .swiper-pagination{
  @apply absolute md:!bottom-[50px] !bottom-[67px] right-5 max-w-fit !left-auto max-[767px]:translate-x-0 lg:translate-x-[-80%]  flex items-center;
}
.testimonialSlider.reviews .swiper-pagination-bullet{
  @apply bg-primary-2 w-[11px] h-[11px] rounded-full opacity-[1];
}
.testimonialSlider.reviews .swiper-pagination-bullet.swiper-pagination-bullet-active{
  @apply bg-primary-5 w-4 h-4
}


/* Date Picker Customize */

.date-picker-container {
  @apply flex flex-col items-center bg-white z-10 p-5 rounded-xl shadow-[0_3px_10px_rgb(0,0,0,0.2)];
}

.react-datepicker {
  @apply font-inter border-none w-full !important;
}

.react-datepicker__header {
  @apply bg-white border-b-0 pt-0 !important;
}

.custom-header {
  @apply flex justify-between items-center pb-5 mb-5 border-b border-[#EBE9E5] !important;
}

.custom-header-date {
  @apply text-xl font-semibold !important;
}

.react-datepicker__current-month {
  @apply hidden !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  @apply flex justify-between !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  @apply w-10 h-10 text-center m-0 text-sm flex items-center justify-center !important;
}

.react-datepicker__day-name {
  @apply text-[#111827] !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply bg-primary-4 text-white rounded-full !important;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300 !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-gray-2 text-white rounded-full !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  @apply text-[#EF4444] !important;
}
.swiper-button-disabled{
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.offers_promotions .swiper-pagination{
  @apply lg:hidden

}

.swiper-pagination {
  bottom: 10px !important; 
}

.custom-bullet {
  width: 16px;
  height: 8px;
  border-radius: 4px;
  background: #E5E5E5;
  opacity: 0.7;
  margin: 0 4px !important;
  transition: background 0.3s ease, opacity 0.3s ease;
}

.swiper-pagination-bullet-active {
  width: 24px;
  background: #FF7A00;
  opacity: 1;
}
.OrangeFilled path {
  @apply !fill-primary-5;
}
.custom-grid{
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
}
.testimonialSlider.testimonialSliderModifier .swiper-pagination-bullet{
  @apply bg-primary-2;
}
.testimonialSlider.testimonialSliderModifier .swiper-pagination-bullet.swiper-pagination-bullet-active{
  @apply bg-primary-5
}
.tabs-wrapper::-webkit-scrollbar{
  @apply hidden;
}
.modal-step::-webkit-scrollbar{
  display: none;
}
input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-ms-thumb {
    opacity:0;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/* For Reschdule Booking Time Dropdown Vidible */
.order-confirmation .bg-white.rounded-lg.overflow-hidden{
  @apply overflow-visible;
}
.fill-dam path{
  @apply fill-dshade-6
}
.fill-ubq path{
  @apply fill-primary-5
}



/* Button Shaking Effect */
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.3s ease-in-out; /* Duration and easing */
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}



/* Add this CSS in your styles file or within a <style> tag in your component */
.custom-list {
  list-style-type: none; 
  padding-left: 0; 
}

.custom-list li {
  position: relative;
  padding-left: 20px; 
}

.custom-list li::before {
  content: ''; 
  position: absolute;
  left: 0; 
  top: 50%; 
  transform: translateY(-50%); 
  width: 8px; 
  height: 8px; 
  border: solid 1px #FF7A00;
  border-radius: 50%;
  background-color: transparent;
}

.custom-list-filled li::before {
  content: ''; 
  position: absolute;
  left: 0; 
  top: 50%; 
  transform: translateY(-50%); 
  width: 8px; 
  height: 8px; 
  border: solid 1px #FF7A00;
  border-radius: 50%;
  background-color: #FF7A00;
}

.cities-slider .swiper-wrapper{
  padding: 5px 0;
}


/*============= Design the Search Bar of (AutoComplete - @react-google-maps/api) =============*/

/* Target the Google Autocomplete dropdown */
.pac-container {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  font-family: 'Inter', sans-serif; /* Use a custom font */
}

/* Style each autocomplete suggestion */
.pac-item {
  padding: 6px !important;
  font-size: 14px !important;
  color: #333 !important;
}

/* Style the highlighted item on hover */
.pac-item:hover {
  background-color: #f0f0f0 !important;
}

/* Style for the main text in each suggestion */
.pac-item .pac-item-query {
  color: #000;
  font-weight: 500;
}

/* Style for secondary text (like city, country) */
.pac-item .pac-matched {
  color: #888;
}

.pac-logo::after{
  display: none !important; 
} 

/*============= Loader =============*/
.loader {
  border: 2px solid transparent;
  border-top-color: gray;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* 
.bottom-drawer {
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
}
.bottom-drawer.active {
  transform: translateY(0);
} */
/* Bottom Drawer Slide-in Animation */
@keyframes slideIn {
  0% {
    transform: translateY(100%); /* Start from below the screen */
  }
  100% {
    transform: translateY(0); /* End at the normal position */
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0); /* Start from the normal position */
  }
  100% {
    transform: translateY(100%); /* End below the screen */
  }
}

/* Bottom Drawer Initial State */
.bottom-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0 0;
  z-index: 50;
  transform: translateY(100%); /* Start below the screen */
  animation: slideOut 0.5s forwards; /* Default to sliding out */
}

/* Active Drawer */
.bottom-drawer.active {
  animation: slideIn 0.5s forwards; /* Slide in when active */
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40; /* Overlay should be just below the drawer */
}



.introjs-tooltip {
  background-color: #fff !important;
  color: #fff !important;
}

.introjs-highlight {
  border: 2px solid #ff6200 !important;
}

.introjs-tooltip {
  min-width: 260px !important;
  max-width: 260px !important;
  border: 1px solid #e06a20 !important;
} 

.introjs-tooltip .introjs-tooltiptext{
  @apply font-inter font-semibold text-wireframe-heading
}
.introjs-button{
  padding: 3px 16px !important; 
}



.blog-description p{
  @apply mb-0.5;
}

.blog-description strong{
  @apply inline-block mt-3;
}

.blog-share .a2a_kit .a2a_svg {
  fill: #1d4ed8 !important; /* Use Tailwind's 'blue-600' or adjust to your preferred blue */
}

.blog-share .a2a_kit a {
  text-decoration: none; /* Optional: Remove underline for text links */
}

/* ======= Privacy policy ======= */
.privacy-policy p{
  @apply tracking-wide
}
.term-conditions p{
  @apply tracking-wide text-gray-500
}


/* ========== SLick SLider ========= */

.delivery-promotion .slick-track{
  @apply !mx-0
}